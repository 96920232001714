<template>
  <div>

    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-component _2 w-nav">
      <div class="navbar-container w-container">
        <a id="w-node-_2bc7849c-fc53-2a49-059f-aa960ee8e78d-0ee8e78b" @click="goToHome" class="navbar-logo-link no-rizer w-nav-brand"><img src="../../../../../public/images/kolobok.svg" loading="lazy" alt="" class="navbar-logo"></a>
      </div>
    </div>
    <div class="section color mid-line">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form one w-container">
        <div class="form-regidet w-form">
          <div class="wrap-steps">
            <a @click="previousPage" class="arrow-left w-inline-block"><img src="../../../../../public/images/Union.svg" loading="lazy" alt="" class="arrow"></a>
            <div class="step">
              <div class="step-number like">
                <div>1</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number active">
                <div class="text-block-7">2</div>
                <div data-w-id="a1680b49-2005-863e-9cc8-d83ed7d2e3cb" class="pulse"></div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>3</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>4</div>
              </div>
            </div>
          </div>
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="6666a4302d30112b2295738b" data-wf-element-id="d73203a7-f081-4301-5c74-acb811806f90">
            <v-form ref="formReg" v-model="valid_reg" lazy-validation>
              <div class="line-progress step-2"></div>
              <div class="tittle-form">
                <h1 class="heading-form" style="margin-bottom: 15px;">Паспортные данные</h1>
              </div>
              <div class="w-layout-vflex flex-form">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Seria-3" class="input-label">Серия</label>
                    <v-text-field
                        outlined
                        v-mask="'####'"
                        inputmode="numeric"
                        v-model="registration_data.passport.series"
                        :rules="passportSeriesRules"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="Number-3" class="input-label">Номер</label>
                    <v-text-field
                        outlined
                        inputmode="numeric"
                        v-mask="'######'"
                        v-model="registration_data.passport.number"
                        :rules="passportNumberRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Data-Vid" class="input-label">Дата выдачи</label>
                    <v-text-field
                        outlined
                        type="date"
                        v-model="registration_data.passport.issued_at"
                        :rules="dateRules"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="Kod-3" class="input-label">Код подразделения</label>
                    <v-text-field
                        outlined
                        type="text"
                        v-mask="'###-###'"
                        inputmode="numeric"
                        v-model="registration_data.passport.division_code"
                        :rules="divisionCodeRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Kem-Vidan-6" class="input-label">Кем выдан</label>
                    <v-text-field
                        outlined
                        type="text"
                        inputmode="text"
                        v-model="registration_data.passport.issued_by"
                        :rules="nameRules"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="Data-3" class="input-label">Дата рождения</label>
                    <v-text-field
                        outlined
                        type="date"
                        v-model="registration_data.profile.birth_date"
                        :rules="dateRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Kem-vidan-3" class="input-label">Место рождения (как в паспорте)</label>
                    <v-text-field
                        outlined
                        type="text"
                        v-model="registration_data.passport.birth_place"
                        :rules="nameRules"
                    ></v-text-field>
                  </div>

                  <div class="input-box check">
                    <label class="radio-button-field w-radio"
                           @click.prevent="selectedGender = false">
                      <div
                          :class="{ 'w--redirected-checked': !selectedGender }"
                          @click.prevent="selectedGender = false"
                          class="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input">
                      </div>
                      <input
                          type="radio"
                          data-name="Radio 3"
                          id="radio-3"
                          name="radio"
                          value='true'
                          style="opacity:0;position:absolute;z-index:-1">
                      <span class="text-classic top w-form-label" for="radio-3">Мужской</span>
                    </label>
                    <label class="radio-button-field w-radio"
                           @click.prevent="selectedGender = true">
                      <div
                          :class="{ 'w--redirected-checked': selectedGender }"
                          @click.prevent="selectedGender = true"
                          class="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input">
                      </div>
                      <input
                          type="radio"
                          data-name="Radio 3"
                          id="radio-3"
                          name="radio"
                          style="opacity:0;position:absolute;z-index:-1"
                          value='false'>
                      <span class="text-classic top w-form-label" for="radio-3">Женский</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="tittle-form">
                <h1 class="heading-form" style="margin-bottom: 15px; margin-top: 15px;">Адрес регистрации</h1>
              </div>
              <div class="w-layout-vflex flex-form">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Region" class="input-label">Регион</label>
                    <v-text-field
                        outlined
                        v-model="registration_data.address.region"
                        :rules="nameRules"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="City-Town" class="input-label">Город / Населенный пункт</label>
                    <v-text-field
                        outlined
                        v-model="registration_data.address.city"
                        :rules="nameRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Street" class="input-label">Улица</label>
                    <v-text-field
                        outlined
                        v-model="registration_data.address.street"
                        :rules="nameRules"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="Department-code" class="input-label">Дом</label>
                    <v-text-field
                        outlined
                        v-model="registration_data.address.house"
                        :rules="houseNumberRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Flat" class="input-label">Квартира</label>
                    <v-text-field
                        id="Apartment"
                        name="Apartment"
                        outlined
                        type="text"
                        v-model="registration_data.address.apartment"
                        :rules="apartmentRules"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <a
                  style="margin-top: 15px"
                  id="openLabel"
                  @click="nextPage"
                  class="button-bust w-button">
                Продолжить
              </a>
              <link rel="prerender" @click="nextPage">
            </v-form>
          </form>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'RegistrationStep2',
  data() {
    return {

      valid_reg: false,

      dateRules: [
        v => !!v || 'Введите корректную дату',
        v => this.isValidDate(v) || 'Неправильный формат даты'
      ],
      nameRules: [
        v => !!v || 'Поле не может быть пустым',
        v => (v && v.length <= 256) || 'Максимальная длина 256 символов'
      ],
      apartmentRules: [
        v => !v || /^[0-9]*$/.test(v) || 'Номер квартиры должен содержать только цифры'
      ],
      houseNumberRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^[0-9a-zA-Zа-яА-Я]+$/.test(v) || 'Номер дома должен содержать только цифры или буквы'
      ],
      emailRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Неправильный формат email'
      ],
      passportSeriesRules: [
        v => !!v || 'Введите серию паспорта',
        v => /^\d{4}$/.test(v) || 'Серия паспорта должна состоять из 4 цифр'
      ],
      passportNumberRules: [
        v => !!v || 'Введите номер паспорта',
        v => /^\d{6}$/.test(v) || 'Номер паспорта должен состоять из 6 цифр'
      ],
      divisionCodeRules: [
        v => !!v || 'Введите код подразделения',
        v => /^\d{3}-\d{3}$/.test(v) || 'Код подразделения должен быть в формате 000-000'
      ],
    };
  },
  components: {
    Footer
  },
  created() {
  },
  computed: {
    selectedGender: {
      get() {
        return this.registration_data.passport.gender;
      },
      set(value) {
        this.registration_data.passport.gender = value;
      }
    },
    registration_data: {
      get() {
        return this.$store.getters.REGISTRATION_DATA;
      },
      set(value) {
        this.$store.commit('SET_REGISTRATION_DATA', value);
      }
    },
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
    goToHome() {
      this.$router.push('/');
    },
    nextPage() {
      if (this.$refs.formReg.validate()) {
        this.$router.push('/registration-step3');
      } else {
        this.$buefy.toast.open({
          message: 'Неправильно заполнены поля',
          type: 'is-danger'
        });
      }
    },
    previousPage() {
      this.$router.push('/registration-step1');
    },
    isValidDate(dateString) {
      // Проверка на пустую строку
      if (!dateString) return false;

      // Регулярное выражение для проверки формата YYYY-MM-DD
      const regEx = /^\d{4}-\d{2}-\d{2}$/;

      // Проверка соответствия формату
      if (!dateString.match(regEx)) return false;

      // Проверка, что дата валидна
      const d = new Date(dateString);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();

      // Проверка на правильное количество дней в месяце
      if (year < 1000 || year > 9999 || month < 1 || month > 12) return false;
      const daysInMonth = new Date(year, month, 0).getDate();
      if (day < 1 || day > daysInMonth) return false;

      return true;
    },
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
